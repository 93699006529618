import request from '@/utils/request'

//采购订单
export const getPurchaseDetail = (params) => request({ url: `/api/system/purchase/bill/getDetail`, method: 'GET', params })
//采购入库单
export const getPurchaseStoreDetail = (params) => request({ url: `/api/system/purchase/store/getDetail`, method: 'GET', params })
//采购退货单
export const getPurchaseReturnDetail = (params) => request({ url: `/api/system/purchase/return/getDetail`, method: 'GET', params })
//销售订单
export const getSaleDetail = (params) => request({ url: `/api/system/sale/bill/getDetail`, method: 'GET', params })
//销售出库单
export const getSaleOutboundDetail = (params) => request({ url: `/api/system/sale/outbound/getDetail`, method: 'GET', params })
//销售退货单
export const getSaleReturnDetail = (params) => request({ url: `/api/system/sale/return/getDetail`, method: 'GET', params })
//库存调整单
export const getStoreAdjustDetail = (params) => request({ url: `/api/system/store/bill/adjust/getDetail`, method: 'GET', params })
//仓库盘点单
export const getStoreCheckDetail = (params) => request({ url: `/api/system/store/check/getDetail`, method: 'GET', params })
//M门店原料订货
export const getDetailMaterialDetail = (params) => request({ url: `/api/system/shop/bill/material/order/getDetail`, method: 'GET', params })
//仓库盘盈单
export const getStoreCheckSurplusDetail = (params) => request({ url: `/api/system/store/bill/inventory/surplus/getDetail`, method: 'GET', params })
//仓库盘亏单
export const getStoreCheckLossDetail = (params) => request({ url: `/api/system/store/bill/inventory/loss/getDetail`, method: 'GET', params })
//仓库报损单
export const getStorLossDetail = (params) => request({ url: `/api/system/store/bill/loss/getDetail`, method: 'GET', params })
//仓库调入单
export const getStorinDetail = (params) => request({ url: `/api/system/store/bill/in/getDetail`, method: 'GET', params })
//仓库调出单
export const getStorOutDetail = (params) => request({ url: `/api/system/store/bill/out/getDetail`, method: 'GET', params })
//仓库调拨单
export const getStorAllotDetail = (params) => request({ url: `/api/system/store/bill/allot/getDetail`, method: 'GET', params })
//生产计划单
export const getProductPlanDetail = (params) => request({ url: `/api/system/produce/plan/getDetail`, method: 'GET', params })
//生产领料单
export const getProductPickDetail = (params) => request({ url: `/api/system/produce/bill/picking/getDetail`, method: 'GET', params })
//生产退料单
export const getProductReturnDetail = (params) => request({ url: `/api/system/produce/bill/return/material/getDetail`, method: 'GET', params })
//生产入库单
export const getProductStoreDetail = (params) => request({ url: `/api/system/produce/bill/storage/getDetail`, method: 'GET', params })
//生产分货单
export const getProductDistributeDetail = (params) => request({ url: `/api/system/produce/bill/goods/distribute/getDetail`, method: 'GET', params })
//门店订货单
export const getShopOrderDetail = (params) => request({ url: `/api/system/shop/bill/order/getDetail`, method: 'GET', params })
//商品组装单
export const getAssembleGoodsBillDetail = (params) => request({ url: `/api/system/store/assemble/goods/getAssembleGoodsBillDetail`, method: 'GET', params })
//商品拆卸单
export const getDisassembleGoodsBillDetail = (params) => request({ url: `/api/system/store/disassemble/goods/getDisassembleGoodsBillDetail`, method: 'GET', params })
//门店销售订单
export const shopSaleGetDetail = (params) => request({ url: `/api/system/shop/bill/shopSale/getDetail`, method: 'GET', params })
//其他订货单
export const otherOrderGetDetail = (params) => request({ url: `/api/system/produce/other/order/getDetail`, method: 'GET', params })
//生产其他原料订货单
export const materialOrderGetDetail = (params) => request({ url: `/api/system/produce/bill/material/order/getDetail`, method: 'GET', params })
//门店订货单
export const getOrderDetail = (params) => request({ url: `/api/system/shop/bill/preOrder/getShopBillOrderInfo`, method: 'GET', params })
//智能配货单
export const autoDistributeDetail = (params) => request({ url: `/api/system/smart/distribute/getCalculateDistributeDetail`, method: 'GET', params })
//生产配料
export const materialGetDetailInfo = (data) => request({ url: `/api/system/produce/bill/match/material/getDetailInfo`, method: 'POST', data })
//成本调整
export const getCostModifyInfo = (params) => request({ url: `/api/system/store/bill/cost/modify/getCostModifyInfo`, method: 'GET', params })
//门店采购
export const getShopPurchaseDetail = (params) => request({ url: `/api/system/shop/bill/purchase/getDetail`, method: 'GET', params })
//门店采购退货
export const getShopPurchaseReturnDetail = (params) => request({ url: `/api/system/shop/bill/purchase/return/getDetail`, method: 'GET', params })
//门店销售单
export const getSaleGetDetail = (params) => request({ url: `/api/system/shop/bill/sale/getDetail`, method: 'GET', params })
//门店报废
export const getShopScrapGetDetail = (params) => request({ url: `/api/system/shop/bill/scrap/getDetail`, method: 'GET', params })
//门店调入
export const getShopMoveInGetDetail = (params) => request({ url: `/api/system/shop/bill/in/getDetail`, method: 'GET', params })
//门店调出
export const getShopMoveOutGetDetail = (params) => request({ url: `/api/system/shop/bill/out/getDetail`, method: 'GET', params })
//门店生产
export const getShopProduceGetDetail = (params) => request({ url: `/api/system/shop/bill/produce/getDetail`, method: 'GET', params })
//门店原料消耗
export const getShopWasteGetDetail = (params) => request({ url: `/api/system/shop/bill/picking/getDetail`, method: 'GET', params })
//门店盘点
export const getShopCheckGetDetail = (params) => request({ url: `/api/system/shop/bill/check/getDetail`, method: 'GET', params })
//门店盘盈单
export const getShopCheckFullGetDetail = (params) => request({ url: `/api/system/shop/bill/inventory/surplus/getDetail`, method: 'GET', params })
//门店盘亏单
export const getShopCheckLoseGetDetail = (params) => request({ url: `/api/system/shop/bill/inventory/loss/getDetail`, method: 'GET', params })
//门店预订单
export const getShopBillOrderInfo = (params) => request({ url: `/api/system/shop/bill/preOrder/getShopBillOrderInfo`, method: 'GET', params })
//生产配料
export const getProductMatchMaterialDetail = (params) => request({ url: `/api/system/produce/bill/match/material/getDetailInfo`, method: 'GET', params })