import request from '@/utils/request'
// 订单汇总明细查询
export function listOrderTotalOrderDetail (params) {
  return request({
    url: '/api/system/produce/report/listOrderTotalOrderDetail',
    method: 'get',
    params
  })
}
// 订单汇总明细查询合计
export function sumListOrderTotalOrderDetail (params) {
  return request({
    url: '/api/system/produce/report/sumListOrderTotalOrderDetail',
    method: 'get',
    params
  })
}

// 生产计划订单汇总查询
export function listOrderTotalPlanDetail (params) {
  return request({
    url: '/api/system/produce/report/listOrderTotalPlanDetail',
    method: 'get',
    params
  })
}
// 生产计划订单汇总查询合计
export function sumListOrderTotalPlanDetail (params) {
  // 发送请求
  return request({
    url: '/api/system/produce/report/sumListOrderTotalPlanDetail',
    method: 'get',
    params
  })
}

// 生产计划订单汇总明细查询
export function listOrderTotalGoodsDetail (params) {
  return request({
    url: '/api/system/produce/report/listOrderTotalGoodsDetail',
    method: 'get',
    params
  })
}
// 生产计划订单汇总明细查询合计
export function sumListOrderTotalGoodsDetail (params) {
  return request({
    url: '/api/system/produce/report/sumListOrderTotalGoodsDetail',
    method: 'get',
    params
  })
}

