import request from '@/utils/request'

// 配方维护分页列表
export function serviceListAPI (params) {
  return request({
    url: '/api/system/produce/bill/bom/list',
    method: 'get',
    params
  })
}
// 新增配方维护
export function serviceAddAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/save',
    method: 'post',
    data
  })
}
//获取配方维护详情
export function serviceDetailAPI (bomId) {
  return request({
    url: '/api/system/produce/bill/bom/getDetail?bomId=' + bomId,
    method: 'get'
  })
}
//获取配方维护详情单击
export function getSubBomInfo({rootBomId, subBomId}) {
  return request({
    url: `/api/system/produce/bill/bom/getSubBomInfo?rootBomId=${rootBomId}&subBomId=${subBomId}`,
    method: 'get'
  })
}
// 修改配方维护
export function serviceUpdateAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/update',
    method: 'put',
    data
  })
}
// 更改状态
export function serviceUpdateStatusAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/updateStatus',
    method: 'post',
    data
  })
}
//分类树(列表)
export function serviceTreeAPI (query) {
  return request({
    url: '/api/system/produce/bill/bom/getAllCategory',
    method: 'get',
    params: query
  })
}
//分类树（商品详情）
export function serviceGoodsTreeAPI (bomId) {
  return request({
    url: '/api/system/produce/bill/bom/getProduceBillBomTree?bomId=' + bomId,
    method: 'get'
  })
}

// 是否停用
export function serviceIsStopAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/isStop',
    method: 'post',
    data
  })
}

// 复制
export function serviceCopyAPI (data) {
  return request({
    url: '/api/system/produce/bill/bom/copy',
    method: 'post',
    data
  })
}
//生产商品列表专属接口
export function queryProduceBillBomGoodsPage (params) {
  return request({
    url: '/api/system/produce/bill/bom/queryProduceBillBomGoodsPage',
    method: 'get',
    params
  })
}

//获取配方的工序
export function getBomProcessSetting (bomId) {
  return request({
    url: '/api/system/mes/process/bill/bom/process/getBomProcessSetting?bomId=' + bomId,
    method: 'get'
  })
}

// 保存配方工序
export function saveBomProcess (data) {
  return request({
    url: '/api/system/mes/process/bill/bom/process/saveBomProcess',
    method: 'post',
    data
  })
}

// 导入
export function uploadFileRequest (data) {
  return request({
    url: '/api/system/produce/bill/bom/listImport',
    method: 'POST',
    timeout: 300000, // 设置超时时间为5min
    data
  })
}
